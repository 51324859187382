.news-detail-page
	.section-page-banner
		display: none !important
.section-news-detail
	.news-detail-title
		padding-bottom: r(24px)
		border-bottom: 1px solid #DCDCDC
		date
			+fz-lh(16px,20px)
			+text(gray-500)
			font-weight: 300
		h1
			+fz-lh(40px,48px)
			font-weight: bold
			margin-top: r(20px)
	.news-detail-content
		padding: r(24px) 0px
		+fz-lh(24px,32px)
		ul
			list-style: disc
			padding-left: r(24px)
			margin-bottom: r(15px)
			li
				margin-bottom: r(10px)
		p
			margin-bottom: r(24px)
		img
			width: 100%
			display: block
	.news-detail-other-news
		position: relative
		.wrap-button-slide
			.btn
				position: absolute
				top: 58.5%
				transform: translateY(-50%)
				&.btn-prev
					right: 100%
					margin-right: r(32px)
				&.btn-next
					left: 100%
					margin-left: r(32px)