.section-service-wrap
	.wrap-list-services
		display: grid
		grid-template-columns: 1fr 1fr 1fr
		gap: r(32px)
		.item-services
			.img
				a
					padding-top: calc(320/480*100%)
		.title
			padding-block: r(24px)
			font-weight: bold
			+fz-lh(24px,32px)