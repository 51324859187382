.home-banner-slider
	position: relative
	.scroll-mouse-wrap
		position: absolute
		width: 100%
		bottom: r(-4px)
		left: 0
		z-index: 2
	.banner-item
		position: relative
		&-caption
			width: 100%
			font-weight: 300
			@extend .heading-2
			+text(white)
			position: absolute
			+mq-min(md)
				bottom: r(132px)
			+mq-max(md)
				bottom: r(40px)
				left: 0
		&-img
			+mq-max(md)
				figure
					+res-ratio(3,4)