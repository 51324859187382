.key-list
	position: relative
	+mq-min(lg)
		height: r(640px)
	.key-background-list
		position: absolute
		+fill
		.key-background-item
			position: absolute
			+fill
			+trans-all
			opacity: 0
			z-index: 2
			&.show
				opacity: 1
			&.default
				z-index: 1
			figure
				height: 100%
				width: 100%
				img
					+fit-cover
	.key-content-list
		position: relative
		z-index: 3
		height: 100%
		+mq-min(lg)
			.row
				+gap-override(0px,0px)
			.container
				max-width: none
				padding: 0
		.key-item
			justify-content: flex-end
			+trans-all
			display: flex
			flex-direction: column
			&-title
				+mq-min(lg)
					height: r(96px)
			+mq-max(lg)
				+layout-col(16px)
				.key-item-img
					figure
						+res-ratio(16,9)
			+mq-min(lg)
				+text(white)
				padding: r(40px)
				height: r(640px)
				border: 1px solid #FFF
				background: linear-gradient(180deg, rgba(0, 0, 0, 0.19) 0%, rgba(0, 0, 0, 0.56) 100%)
				+trans-all
			+on-hover
				background: linear-gradient(180deg, rgba(102, 102, 102, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%)
