$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $button
	@each $color-hover, $value-hover in $button-hover
		.btn-#{$color}
			background-color: $value
			color: contrast-color($value, #333, #fff)
			+on-hover
				background-color: $value-hover

		.btn-border-#{$color}
			border: 1px solid $value
			color: $value
			+on-hover
				background-color: $value
				color: contrast-color($value, #333, #fff)

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(12px) r(24px)
	display: inline-flex
	+trans-all
	+font(700,20px,26px)
	+flex-center
	text-transform: uppercase
	gap: r(12px)
	border-radius: 999px

.btn-action
	display: inline-flex
	+flex-center
	+box(40px)
	font-size: r(20px)


.swiper-btn
	+text(white)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	background: rgba(#000 , 0.2)
	+remove(lg,max)
	+on-hover
		+bg(primary-500)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&.no-box
		width: auto
		height: auto
		background: transparent
		+text(gray-300)
		+on-hover
			+text(primary-500)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(30px)
			&.swiper-prev
				right: 100%
			&.swiper-next
				left: 100%
			+mq-max(2xl)
				margin: 0 r(10px)

		&.is-no-gap
			.swiper-btn
				margin: 0 r(32px)
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)