.section-about-2
	.wrap-grid
		display: grid
		grid-template-columns: repeat(4, 1fr)
		gap: r(32px)
	.item-about-2
		padding-top: calc(236/352*100%)
		.wrap
			+bg(primary-700)
			display: flex
			align-items: center
			flex-direction: column
			color: white
			font-weight: bold
			text-align: center
			padding-block: r(30px)
			padding-inline: r(42px)
			.title
				margin-top: r(10px)
				+fz-lh(24px,32px)