.news-item
	&-img
		figure
			+res-ratio(480,240)
	&-caption
		padding: r(20px) 0 0
	&-date
		@extend .body-3
		+text(gray-400)
	&-title
		@extend .heading-5
		font-weight: 700
		margin-top: r(8px)
		margin-bottom: r(12px)
		+trans-all
		+line(2)
		height: r(96px)
	&-brief
		@extend .body-1
		+text(gray-500)
		+layout-col(12px)
	+on-hover
		.news-item-title
			+text(primary-700)