.tabs-style-1
	display: flex
	gap: r(12px)
	overflow: auto
	li
		&:first-child
			margin-left: auto
		&:last-child
			margin-right: auto
		&.active
			a
				+bg(primary-700)
		a
			padding: r(12px) r(32px)
			+bg(gray-500)
			font-weight: bold
			display: inline-block
			color: white
			transition: .3s all  ease-in-out
			+fz-lh(20px, 26px)
			&:hover
				+bg(primary-700)
