.product-item
	position: relative
	display: block
	&-caption
		+layout-col(8px)
		align-items: flex-start
		padding: r(20px)
		background: color(gray-100)
		+mq-min(lg)
			position: absolute
			+fill
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.33) 0%, rgba(0, 0, 0, 0.00) 100%)
			+text(white)
			padding: r(40px)
	&-title
		@extend .heading-4
		font-weight: 700
	&-small
		.product-item-title
			@extend .heading-5
		.product-item-caption
			padding: r(32px)
		+mq-min(lg)
			.product-item-brief
				display: none
	&-brief
		@extend .body-1
		font-weight: 500
		+mq-min(lg)
			+trans-all
			opacity: 0
	.btn
		margin-top: r(16px) !important
		+mq-min(lg)
			+trans-all
			opacity: 0
	+on-hover
		.product-item-brief,.btn
			opacity: 1