.home-1
	.item-list
		.row
			justify-content: center
			+gap-override(16px)
			> *
				+flex-width(1/3*100%)
				+mq-min(md)
					+flex-width(1/4*100%)
				+mq-min(lg)
					+flex-width(1/7*100%)
			.title-col
				+mq-max(md)
					+flex-width(100%)
		.item
			+layout-col(8px)
			min-height: r(200px)
			padding: 0 r(20px)
			+mq-max(md)
				min-height: auto
				padding: 0
				align-items: center
				text-align: center
			.title
				+text(primary-700)
				> *
					display: block
			.icon
				width: r(64px)
				+mq-max(md)
					margin-left: auto
					margin-right: auto
				figure
					+res-ratio(1,1)
					img
						object-fit: contain
			&-title
				clip-path: polygon(0% 0%, 100% 0, 100% 75%, 50% 100%, 0 75%)
				+mq-max(md)
					padding: r(12px) r(24px) r(24px)
					min-height: r(120px)
					text-align: center
				+mq-min(md)
					clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)
					padding: r(24px)