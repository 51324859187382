.section-contact-main
	h1
		+text(primary-700)
		margin-bottom: r(32px)
	.wrap-dealer
		display: none
	.wrap-address-list
		li
			font-size: r(24px)
			&+li
				border-top: 1px solid #f1f1f1
				padding-top: r(24px)
				margin-top: r(24px)
			h3
				font-weight: bold
				margin-bottom: r(13px)
			p
				line-height: 1.25
				strong
					color: color(primary-700)
					font-weight: 400
			a.map
				display: block
				color: #008BFF
				margin-top: r(10px)
				margin-bottom: r(24px)
				&::before
					content: '\f3c5'
					font-family: Font Awesome\ 6 Pro
					font-size: r(18px)
					margin-right: r(20px)
	.wrap-form-main
		background-color: #F6F6F6
		padding: r(60px) r(32px)
		p
			+fz-lh(24px,32px)
			margin-bottom: r(26px)
		.wrap-form-grid
			display: grid
			grid-template-columns: 1fr 1fr
			gap: r(16px)
			input, textarea
				color: #666666
				border: 1px solid #E1E1E1
				height: r(50px)
				+fz-lh(20px,26px)
				padding: 0 r(20px)
				transition: .3s all ease-in-out
				&:hover, &:focus
					border: 1px solid color(primary-700)
			.qparagraph
				grid-column: span 2
			textarea
				width: 100%
				padding-block: r(10px)
				height: r(132px)
	.frm-btnwrap
		display: flex
		align-items: center
		justify-content: center
		grid-column: span 2
		.frm-btn
			margin-left: 0
			color: white
			padding: 0px r(24px)
			border-radius: 50px
			height: r(50px)
			display: flex
			align-items: center
			justify-content: center
			position: relative
			gap: r(16px)
			+bg(primary-700)
			&::after
				content: ''
				background-image: url('../img/icon-2.png')
				display: inline-block
				width: 17px
				height: 18px
			&::before
				content: 'Send'
				font-weight: bold
				text-transform: uppercase
				font-size: r(24px)
			input
				background-color: transparent
				color: transparent
				position: absolute
				top: 0
				left: 0
				height: 100%
				width: 100%
				padding: 0
				border: 0
