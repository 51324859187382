.section-recruitment-detail
	.title-job
		padding-bottom: r(24px)
		font-weight: bold
		border-bottom: 1px solid #DCDCDC
	.wrap-information
		display: flex
		flex-direction: column
		padding-top: r(26px)
		gap: r(30px)
		.item-information
			background-color: #F6F6F6
			padding: r(30px)
			.title
				+fz-lh(24px,32px)
				+text(primary-700)
				font-weight: bold
				margin-bottom: r(12px)
			.content
				+fz-lh(24px,32px)
				a
					+text(primary-700)
					&:hover
						text-decoration: underline
				ul
					list-style: disc
					padding-left: r(18px)
					li
						&+li
							margin-top: r(5px)
	.wrap-button-recruitment
		+bg(primary-50)
		padding: r(32px)
		display: flex
		flex-direction: column
		gap: r(24px)
		.btn-apply,.btn-download
			display: flex
			align-items: center
			justify-content: center
			height: r(50px)
			width: 100%
			color: white
			+bg(primary-700)
			@apply nn-transition
			&:hover
				+bg(primary-600)
		.btn-download
			+bg(primary-600)
			&:hover
				+bg(primary-700)
	.wrap-other-recruitment
		display: flex
		flex-direction: column
		.heading-2
			font-weight: 400
			padding: r(30px) 0px
		.item-other-recruitment
			+bg(gray-50)
			padding: r(24px)
			display: flex
			flex-direction: column
			gap: r(10px)
			border: 2px solid transparent
			transition: .3s all cubic-bezier(0.6, 0.6, 0.1, 1)
			margin-bottom: r(32px)
			position: relative
			.title
				&::before
					content: ''
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
			&:hover
				.title
					color: #E41913
			&:hover
				border: 2px solid #E41913
			.title
				+fz-lh(24px,32px)
				font-weight: bold
			.location
				+fz-lh(20px,26px)
			.exp-date
				+fz-lh(20px,26px)
				color: #E41913

