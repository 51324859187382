.nn-text-primary
	+text(primary-700)
.line-clamp-2
	ul
		display: none
	p
		display: none
		&:first-child
			display: block
			+line(2)
.line-clamp-4
	p
		display: none
		&:first-child
			display: block
			+line(4)
	ul
		display: none

.nn-img-ratio
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: 100%
	figure
		display: block
	img,iframe
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
		transition: .3s all ease-in-out
.nn-div-ratio
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: 100%
	&>div
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		object-fit: cover
		transition: .3s all ease-in-out
.nn-content-format
	p + p
	margin-top: r(16px)
.nn-zoom-img
	overflow: hidden
	&:hover
		img
			transform: scale(1.1)
	img
		transition: .3s all cubic-bezier(0.6, 0.6, 0.1, 1)
.nn-mx-auto
	margin-left: auto
	margin-right: auto
.nn-btn-sw-1
	+flex-center
	width: r(50px)
	height: r(50px)
	border: 1px solid color(gray-500)
	border-radius: 0 !important
.nn-transition
	transition: .3s all cubic-bezier(0.6, 0.6, 0.1, 1)
