.form-group
	+all-text-inputs
		height: r(40px)
		width: 100%
		background: white
		border: 1px solid color(gray-100)
		padding: 0 r(24px)
		+text(white)
		font-size: r(14px)
		+placeholder
			+text(gray-500)
			opacity: 1
	select
		height: r(40px)
		width: 100%
		+bg(white)
		border: 1px solid color(gray-100)
		padding: 0 r(24px)
		+text(gray-500)
		font-size: r(14px)
	textarea
		height: r(120px)
		padding: r(12px) r(24px)

.frm-btnwrap
	margin-top: r(20px)
	.label
		display: none

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	min-width: r(108px)
	// &:before
	// 	+center(Y)
	// 	right: r(16px)
	// 	content: '\f178'
	// 	font-family: "Font Awesome 6 Pro"
	// 	+text(white)
	// 	pointer-events: none
	// 	font-weight: 300
	// 	+fz(20px)
	input[type="submit"]
		+trans-all
		+bg(primary-600)
		+text(white)
		border-radius: r(8px)
		border: 0
		height: r(44px)
		width: 100%
		cursor: pointer
		+fz(16px)
		text-transform: uppercase
		font-weight: 700
		padding: 0 r(56px) 0 r(24px)