.pages,.modulepager
	margin-top: r(110px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		gap: r(12px)
		li
			list-style: none
			display: inline-block
			text-align: center
			&:first-child
				margin-left: 0
			a,span
				justify-content: center
				align-items: center
				display: flex
				text-decoration: none
				width: r(50px)
				height: r(50px)
				font-size: r(20px)
				border: 1px solid color(gray-500)
				font-weight: 700
				+text(gray-500)
				+trans-all
			&.active,&:hover
				a,span
					border-color: color(primary-700)
					+bg(primary-700)
					+text(white)

.no-pagination
	.pages
		display: none
