.section-recruitment
	.page-desc
		max-width: r(1312px)
		margin-left: auto
		margin-right: auto
		+fz-lh(24px,32px)
	.wrap-table-main
		margin-top: r(32px)
		table
			width: 100%
			th
				font-weight: bold
				color: white
				background-color: color(primary-700)
				font-size: r(24px)
				padding: r(22px) r(20px)
				font-weight: bold
				border: 1px solid #CEDBE6
				text-align: center
				&:nth-child(1)
					width: 80px
				&:nth-child(2)
					width: 29%
					text-align: left
				&:nth-child(3)
					width: 34%
					text-align: left
			td
				font-size: r(20px)
				padding: r(10px) r(19px)
				border: 1px solid #CEDBE6
				&:nth-child(1)
					text-align: center
				&:nth-child(4),&:nth-child(5)
					text-align: center
				p
					line-height: 1.4
				&:nth-child(2)
					a
						transition: .3s all  ease-in-out
						&:hover
							+text(primary-700)
							text-decoration: underline
				a.view-link
					+text(primary-700)
					font-weight: 300
					&:hover
						text-decoration: underline