.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(140px)
	right: r(4px)
	ul
		li
			& + li
				margin-top: r(4px)
			.btn-circle
				+flex-center
				+reset-link
				display: inline-flex
				+box(64px)
				font-size: r(12px)
				transition: 0.3s all
				+bg(gray-300)
				+text(white)
				flex-direction: column
				gap: r(4px)
				em
					font-size: r(24px)
			&.has-tooltip
				position: relative
				.btn-circle
					position: relative
					z-index: 2
				.tooltip
					position: absolute
					right: 0
					top: 0
					bottom: 0
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					+bg(white)
					+text(secondary-500)
					border-radius: 999px
					z-index: 1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)

.fixed-cta
	position: fixed
	z-index: 50
	top: r(90px)
	right: 0
	ul
		+layout-col(12px)
		li
			.full-link
				position: absolute
				inset: 0
			.wrap-box
				display: flex
				position: relative
				height: r(64px)
				align-items: center
				+text(white)
				background-color: #f6f6f6
				box-shadow: 4px 4px 8px 4px rgba(0, 0, 0, 0.24)
				transform: translateX(r(136px))
				overflow: hidden
				width: r(200px)
				+trans-all
				+on-hover
					+bg(primary-700)
					transform: none
					span
						opacity: 1
					.wrap-flex
						color: white
				.wrap-flex
					display: flex !important
					flex-direction: column
					justify-content: center
					align-items: center
					padding: r(16px)
					height: 100%
					gap: r(2px)
					color: #e00a14ff
					+trans-all
					p
						font-weight: 700
				em
					+fz(32px)
					width: r(32px)
					flex-shrink: 0
				span
					flex: 1
					display: inline-block
					padding: 0 r(10px)
					border-left: 1px solid color(white)
					@extend .body-4
					font-weight: 700
					opacity: 0
					+trans-all
					+line(2)