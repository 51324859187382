.section-about-3
	.wrap-item-symmetry
		.item-symmetry
			display: grid
			grid-template-columns: 1fr 1fr
			gap: r(32px)
			margin-bottom: r(32px)
			&:nth-child(2n)
				.content
					order: -1
			.img
				position: relative
				a
					position: absolute
					top: 0
					left: 0
					display: block
					width: 100%
					height: 100%
					figure
						display: block
						width: 100%
						height: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
			.content
				+bg(gray-50)
				padding: r(53px) r(32px)
				display: flex
				flex-direction: column
				min-height: r(490px)
				h3
					+fz-lh(24px,32px)
					font-weight: bold
				.desc
					ul
						list-style: disc
						padding-left: r(20px)
						display: flex
						flex-direction: column
						row-gap: r(4px)
						li
							+fz-lh(24px,32px)

