.recruitment-detail-page
	.fancybox-content
		height: 100% !important
.apply-frm
	padding: r(64px)
	max-width: 924px
	margin: 0 auto
	background-color: #fff
	position: relative
	.close-button
		position: absolute
		top: 0
		right: 0
		width: 50px
		height: 50px
		color: white
		display: flex
		justify-content: center
		align-items: center
		font-size: 20px
		+bg(primary-700)
	.form-group.title-form
		input
			+fz-lh(40px,48px)
			margin-bottom: r(24px)
			color: black
			font-weight: bold
			border: 0
			padding: 0
	.wrap-grid-form
		display: grid
		grid-template-columns: 1fr 1fr
		gap: r(16px)
		.form-group:not(.attachfile1):not(.attachfile2)
			position: relative
			.fa-exclamation-triangle
				position: absolute
				top: 50%
				right: 10px
				transform: translateY(-50%)
			label
				display: none
			input
				width: 100%
				height: 50px
				padding: 0px r(20px)
				color: black
				border: 1px solid #E1E1E1
				font-weight: 300
				+fz-lh(20px,26px)
			&.textarea
				grid-column: span 2
				width: 100%
				textarea
					padding: r(12px) r(20px)
					height: r(132px)
					color: black
					border: 1px solid #E1E1E1
					font-weight: 300
					+fz-lh(20px,26px)
		[class*='attachfile']
			.RadUpload
				width: auto !important
			label
				+text(gray-500)
				+fz-lh(20px,26px)
				font-weight: 300
				span
					+text(primary-700)
			.ruRemove
				display: flex
				align-items: center
				background-position: 3px -72px
			.ruFileWrap
				height: r(50px)
				padding: r(0px) r(24px)
				+bg(gray-500)
				color: white
				display: inline-flex
				align-items: center
				justify-content: center
				font-weight: bold
				transition: .3s all  ease-in-out
				border-radius: 50px
				margin-top: r(16px)
				position: relative
				+fz-lh(20px,26px)
				text-transform: uppercase
				gap: r(10px)
				cursor: pointer
				span.ruUploadProgress
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					display: flex
					align-items: center
					justify-content: center
					background-position: 12px 3%
					+bg(gray-500)

				&:hover
					+bg(primary-700)
				&::before
					content: 'Upload File'
				&::after
					content: '\f346'
					margin-left: 5px
					display: inline-block
					font-family: Font Awesome\ 6 Pro
				input
					position: absolute
					top: 0
					left: 0
					width: 100%
					height: 100%
					opacity: 0
	.frm-btnwrap
		.frm-btn
			margin-left: 0
			color: white
			padding: 0px r(24px)
			border-radius: 50px
			height: r(50px)
			display: inline-flex
			align-items: center
			justify-content: center
			position: relative
			gap: r(16px)
			+bg(primary-700)
			&::after
				content: ''
				background-image: url('../img/icon-2.png')
				display: inline-block
				width: 17px
				height: 18px
			&::before
				content: 'Send'
				font-weight: bold
				text-transform: uppercase
				font-size: r(24px)
			input
				background-color: transparent
				color: transparent
				position: absolute
				top: 0
				left: 0
				height: 100%
				width: 100%
				padding: 0
				border: 0
.recruitment-detail-page
	.fancybox-content
		background-color: transparent !important