.global-header
	+bg(white)
	position: relative
	z-index: 2
	+mq-max(lg)
		padding: r(16px) 0

.header-left
	gap: r(64px)

.logo
	width: r(240px)
	a
		display: block
		+res-ratio(240,28)
	+mq-max(sm)
		width: r(200px)

.search-wrapper
	width: r(24px)

.search-toggle
	display: block
	+res-ratio(1,1)

.menu
	ul
		display: flex
		gap: r(48px)
		+mq-max(lg)
			flex-direction: column
			gap: r(20px)
		li
			a
				display: flex
				align-items: center
				height: r(80px)
				text-transform: uppercase
				@extend .heading-5
				+mq-max(lg)
					height: auto
					+text(white)
				+on-hover
					+text(primary-700)
			&.active
				a
					+text(primary-700)
					+mq-max(lg)
						+text(white)
						text-decoration: underline
.header-right
	gap: r(16px)

.header-divider
	+box(4px)
	+bg(gray-200)
	+mq-max(sm)
		display: none

.menu-wrapper
	+mq-max(lg)
		position: absolute
		top: 100%
		left: 0
		width: 100%
		+bg(primary-700)
		border-top: 1px solid #fff
		display: none
		padding: r(32px)


.language
	.current
		display: flex
		gap: r(6px)
		align-items: center
		+text(primary-700)
		@extend .heading-5
		.fa-chevron-down
			+text(gray-400)
	.dropdown-content
		min-width: auto
		z-index: 2
		ul
			padding: r(8px)
			display: flex
			flex-direction: column
			gap: r(8px)
			+text(gray-700)

.hotline-wrapper
	+mq-max(lg)
		margin-top: r(20px)
		a.btn
			+bg(white)
			+text(primary-700)

.search-popup
	position: fixed
	z-index: 1001
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	pointer-events: none
	display: none
	.search-container
		width: 100%
		height: 100%
		+flex-center
		display: flex
		pointer-events: none
	.searchbox
		pointer-events: all
		position: relative
		input
			width: 70vw
			height: r(48px)
			padding: 0 r(72px) 0 r(20px)
			+fz(20px)
		button
			position: absolute
			top: 0
			right: 0
			height: 100%
			width: r(48px)
			background: transparent
			border: 0
			+fz(20px)

.menu-toggle
	+box(44px)
	display: flex
	+flex-center
	+bg(primary-700)
	+text(white)

.backdrop
	+fill
	position: fixed
	opacity: 0.5
	+bg(gray-900)
	z-index: 1000
	display: none
