.global-footer
	.footer-col
		&:not(.first-column)
			.footer-item
				padding-left: r(32px)
				border-left: 1px solid color(gray-400)
				position: relative
			&.has-line
				.footer-item
					position: relative
					&::before
						+pseudo
						width: 2px
						height: r(26px)
						+bg(white)
						left: 0
						top: 0

.footer-item
	h3
		@extend .heading-5
		font-weight: 700
		margin-bottom: r(24px)
	&-info
		@extend .body-1
		h3
			margin-bottom: 0
		* + h3
			margin-top: r(24px)
		* + *
			margin-top: r(8px)
		a
			&:hover
				text-decoration: underline
	&-link
		ul
			+layout-col(12px)
			li
				a
					&:hover
						text-decoration: underline
	&-certificate
		ul
			display: flex
			gap: r(8px)
			flex-wrap: wrap
			li
				+box(80px)
				border-radius: r(16px)
				+bg(white)
				border: r(8px) solid color(white)
				img
					filter: grayscale(1)
	&-social
		ul
			display: flex
			gap: r(8px)
			li

				a
					+circle(48px)
					display: flex
					+flex-center
					+fz(20px)
					+text(gray-500)
					+bg(gray-100)
					+trans-all
					svg
						path
							fill: #818181
					&:hover
						+bg(primary-700)
						+text(white)
						svg
							path
								fill: white
	&-copyright
		h3
			width: r(240px)
			a
				display: block
				+res-ratio(240,28)
				img
					object-fit: contain